import {useState,useEffect } from "react";

import {
  Nav,
  NavBar,
  NavItem
} from '../styled/Navbar';


function MenuNavbar({ activeTab, setActiveTab }) {
  
  const handleClick = (e) => {
    e.preventDefault();
    console.log(e);
    setActiveTab(e.target.innerHTML);
  };

  const menuTabs = ['food','dessert','cocktails','beer//wine'];

  const navItems = menuTabs.map((tab, index) => (
    <>
      <NavItem
        onClick={(e) => handleClick(e)}
        activeTab={activeTab === tab ? true : false}
        key={index}
      >{tab}</NavItem>
    </>
  ))

  return (
    <Nav>
    <NavBar>
        {navItems}
    </NavBar>
  </Nav>
);
};

export default MenuNavbar